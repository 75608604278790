.heading-1 {
  font-family: 'SVN-Poppins-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 88px;
  line-height: 96px;
  color: var(--content-primary);
}

.heading-2 {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 108px;
  color: var(--content-primary);
}

.heading-3 {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 84px;
  color: var(--content-primary);
}

.heading-4 {
  font-family: 'SVN-Poppins-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: var(--content-primary);
}

.heading-5 {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: var(--content-primary);
}

.heading-6 {
  font-family: 'SVN-Poppins-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--content-primary);
}


.heading-6-bold {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--content-primary);
}

.heading-7 {
  font-family: 'SVN-Poppins-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--content-primary);
}

.heading-7-bold {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--content-primary);
}

.heading-8 {
  font-family: 'SVN-Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--content-primary);
}

.heading-8-bold {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--content-primary);
}

.heading-9 {
  font-family: 'SVN-Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--content-secondary);
}

.heading-10 {
  font-family: 'SVN-Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: var(--content-primary);
}

.heading-11 {
  font-family: 'SVN-Poppins-ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: var(--content-primary);
}

.heading-11-bold {
  font-family: 'SVN-Poppins-Bold';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: var(--content-primary);
}

/* === start mobile === */
@media (max-width: 980px) {
  .heading-1 {
    font-family: "SVN-Poppins-Bold";
    font-size: 48px;
    line-height: 56px;
  }

  .heading-2 {
    font-size: 48px;
    line-height: 72px;
  }

  .heading-3 {
    font-size: 32px;
    line-height: 40px;
  }

  .heading-6 {
    font-size: 16px;
    line-height: 24px;
  }

  .heading-4 {
    font-size: 28px;
    line-height: 36px;
  }

  .heading-10 {
    font-size: 28px;
    line-height: 42px;
  }

  .heading-11 {
    font-size: 28px;
    line-height: 36px;
  }
  
  .heading-7, .heading-7-bold {
    font-size: 14px;
    line-height: 20px;
  }

  .heading-11-bold {
    font-size: 24px;
    line-height: 32px;
  }
}