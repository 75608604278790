@import "scss/theme";
@import "scss/margin";
@import "scss/typography";
@import "scss/common";
@import "scss/button";
@import "scss/landingpage";
@import "scss/responsive";

@font-face {
  font-family: "SVN-Poppins-ExtraBold";
  src: url("assets/fonts/SVN-Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "SVN-Poppins-Bold";
  src: url("assets/fonts/SVN-Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SVN-Poppins-Regular";
  src: url("assets/fonts/SVN-Poppins-Regular.ttf") format("truetype");
}
