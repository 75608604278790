:root {
  // color palette
  --primary: #f833a9;

  // bg
  --bg-primary: #fff;
  --bg-secondary: rgba(31, 27, 84, 0.05);
  --bg-accent-primary:  #4169E1;
  --bg-terny-primary: #F5F7FD;
  --bg-scrimPrimary: rgba(0, 0, 0, 0.6);

  // content
  --content-primary: #131416;
  --content-secondary: #797588;
  --content-always-light-primary: #fff;
  --content-always-dark-primary: #131416;
  --content-inverse-primary: #fff;

  // line
  --line-primary: #E7E6EC;
}

[data-theme="dark"] {
 // color palette
 --primary: #f833a9;

 // bg
 --bg-primary: #151618;
 --bg-secondary: rgba(225, 235, 255, 0.1);
 --bg-terny-primary: #171a22;

  // content
  --content-primary: #fff;
  --content-secondary: #A8ABB2; 
  --content-inverse-primary: #131416;

  // line
  --line-primary: #3B3E45;
}
