* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

body {
  background-color: var(--bg-primary);
}

a {
  text-decoration: none;
  color: var(--content-primary);
}

ul {
  list-style: none;
}

ul li {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

.container {
  width: 1366px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 83px;
} 

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-color-white {
  color: #fff !important;
}

.font-weight-bold {
  font-weight: bold;
}

.pl-0 {
  padding-left: 0 !important;
}

.text-color-content-inverse-primary {
  color: var(--content-inverse-primary) !important;
}

.text-color-content-always-light-primary {
  color: var(--content-always-light-primary) !important;
}

.text-color-content-secondary {
  color: var(--content-secondary) !important;
}

.d-flex {
  display: flex;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mt-48 {
  margin-top: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.wrapper {
  background: url(../assets/bg-main.png);
  background-size: 100%;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}